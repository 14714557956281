import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Alexa35 = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselAlexa35.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Arri Alexa 35"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Arri Alexa 35</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>
              A ALEXA 35 é a menor câmera de produção com todos os recursos da
              ARRI de todos os tempos, reunindo os recursos e o poder de
              processamento de uma ALEXA maior em um corpo de tamanho Mini que
              pode gravar 4K nativo a até 120 qps. A operação rápida e fácil é
              garantida por meio de inúmeras melhorias de usabilidade e uma
              estrutura de menu simples que será intuitivamente familiar para as
              equipes. ALEXA 35 é a melhor câmera A, câmera B e câmera de ação
              do mercado, tudo em um.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>17 stops de range dinâmico</li>
                <li>Sensitivity aperfeiçoada: menos ruído, maior ISO</li>
                <li>Cores mais precisas</li>
                <li>Mais controle criativo</li>
                <li>
                  Mais fácil de lidar com quaisquer condições de iluminação,
                  mais flexível na pós
                </li>
                <li>
                  EI 160 a EI 6400, com precisão de cor mantida em todos os
                  níveis de exposição
                </li>
                <li>
                  Opção de sensibilidade aprimorada para imagens ainda mais
                  nítidas com pouca luz
                </li>
                <li>4.6K Super 35 sensor</li>
                <li>Operação rápida e fácil</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <div className="m-auto">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/ARRI-ALEXA-35-Brochure.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      Brochura
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/ARRI-ALEXA-35-Workflow-Post-Guide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      guia
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/ARRI-ALEXA-35-ARRI-Textures.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      guia de texturas
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default Alexa35

export const query = graphql`
  {
    allCarouselAlexa35: allFile(
      filter: { relativePath: { regex: "/alexa-35/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
